import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Link } from 'docz';
import { MultiSelect } from '@entur/dropdown';
import { SmallExpandableAlertBox } from '@entur/alert';
import { CodeText, Link as LinkText } from '@entur/typography';
import { ExpandableText } from '@entur/expand';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import { CodeBlock } from '~/components/CodeBlock';
import PageHeader from '~/components/PageHeader';
import { FILTERS, dropdownItems, countries, cities, fuzzyMatch } from './dropdownItems';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [selectedCities, setSelectedCities] = React.useState([])\n  return (\n    <MultiSelect\n      label=\"Velg fine byer\"\n      items={cities}\n      selectedItems={selectedCities}\n      onChange={setSelectedCities}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      MultiSelect,
      SmallExpandableAlertBox,
      CodeText,
      LinkText,
      ExpandableText,
      Playground,
      ImportStatement,
      CodeBlock,
      PageHeader,
      FILTERS,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      margin: 'auto',
      width: '50%'
    }} mdxType="Playground">
  {() => {
        const [selectedCities, setSelectedCities] = React.useState([]);
        return <MultiSelect label="Velg fine byer" items={cities} selectedItems={selectedCities} onChange={setSelectedCities} mdxType="MultiSelect" />;
      }}
    </Playground>
    <br />
    <SmallExpandableAlertBox title="Migrering fra v4 -> v5" variant="information" mdxType="SmallExpandableAlertBox">
Hvis du ikke ønsker å migrere alle <CodeText mdxType="CodeText">MultiSelect</CodeText>-er med en gang kan du:
      <ul>
  <li>
    Endre navn og import for eksisterende <CodeText mdxType="CodeText">MultiSelect</CodeText> til{' '}
    <CodeText mdxType="CodeText">MultiSelectDeprecated</CodeText>
  </li>
      </ul>
I v5 er MultiSelect kontrollert, så du må selv holde på state for valgte elementer.
Dette løser du ved å:
      <ul>
  <li>
    Bruke <CodeText mdxType="CodeText">React.useState</CodeText> eller lignende til lagring av
    valgte elementer
  </li>
  <li>
    Gi inn state som holder styr på valgte elementer i prop-en{' '}
    <CodeText mdxType="CodeText">selectedItems</CodeText>. Obs, typen på denne er nå kun{' '}
    <CodeText mdxType="CodeText">NormalizedDropdownItem[]</CodeText>
  </li>
  <li>
    Sørg for at <CodeText mdxType="CodeText">onChange</CodeText> oppdaterer state-en med de valgte
    elementene som send inn som argument
  </li>
      </ul>
    </SmallExpandableAlertBox>
    <h2 {...{
      "id": "bruk"
    }}>{`Bruk`}</h2>
    <p><inlineCode parentName="p">{`MultiSelect`}</inlineCode>{` er en komponent som lar brukeren velge ett eller flere elementer fra en liste. Komponenten
viser frem en liste med valg bassert på `}<inlineCode parentName="p">{`items`}</inlineCode>{`-prop-en, der `}<inlineCode parentName="p">{`items`}</inlineCode>{` kan være en liste med `}<inlineCode parentName="p">{`string`}</inlineCode>{` eller
objekter med `}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<inlineCode parentName="p">{`label`}</inlineCode>{` og `}<inlineCode parentName="p">{`icons`}</inlineCode>{` - eller en blanding av disse. For å dekke situasjoner
der valgalternaltiver kommmer fra et API eller lignende støtter `}<inlineCode parentName="p">{`items`}</inlineCode>{` også synkrone og asynkrone funksjoner
som returnerer samme type liste som `}<inlineCode parentName="p">{`items`}</inlineCode>{` aksepterer. `}</p>
    <p>{`Når brukeren velger noe dukker valget opp
som en `}<inlineCode parentName="p">{`Chip`}</inlineCode>{` i inputfeltet. `}<inlineCode parentName="p">{`MultiSelect`}</inlineCode>{` tilbyr også et søkefelt for filtrering av valg, filtreringen her kan
overstyres gjennom `}<inlineCode parentName="p">{`itemFilter`}</inlineCode>{`-prop-en – se `}<a parentName="p" {...{
        "href": "#egendefinert-filtreringsfunksjon"
      }}>{`Egendefinert filtreringsfunksjon`}</a>{` for mer info.`}</p>
    <h3 {...{
      "id": "kom-i-gang"
    }}>{`Kom i gang`}</h3>
    <p>{`MultiSelect har tre påkrevde props: `}<inlineCode parentName="p">{`label`}</inlineCode>{`, `}<inlineCode parentName="p">{`items`}</inlineCode>{` og `}<inlineCode parentName="p">{`selectedItems`}</inlineCode>{`. I tillegg har den også
`}<inlineCode parentName="p">{`onChange`}</inlineCode>{` som ikke er påkrevd, men som er nødvendig for å gjøre komponenten interaktiv.`}</p>
    <p><inlineCode parentName="p">{`label`}</inlineCode>{` er feltets navn og beskriver hva du velger. `}<inlineCode parentName="p">{`items`}</inlineCode>{` tar inn listen med valgalternaltiver,
se `}<a parentName="p" {...{
        "href": "#liste-med-valgalternaltiver"
      }}>{`Liste med valgalternaltiver`}</a>{` for mer info. `}<inlineCode parentName="p">{`selectedItems`}</inlineCode>{` og `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` jobber sammen
for å holde styr på state for valgte elementer. `}<inlineCode parentName="p">{`selectedItems`}</inlineCode>{` skal være av typen
`}<inlineCode parentName="p">{`NormalizedDropdownItemType[]`}</inlineCode>{` (Obs: tom liste, `}<em parentName="p">{`ikke`}</em>{` `}<inlineCode parentName="p">{`null`}</inlineCode>{` hvis ingen er valgt) og `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` skal være en funksjon som tar inn alle valgte
elementer og oppdaterer verdien på `}<inlineCode parentName="p">{`selectedItems`}</inlineCode>{` til denne listen. Se `}<a parentName="p" {...{
        "href": "#standard-multiselect"
      }}>{`Standard MultiSelect`}</a>{`-eksemplet
for å se dette i praksis.`}</p>
    <CodeBlock hideLineNumbers wrapLongLines={false} language="ts" mdxType="CodeBlock">
  {`// Definition of NormalizedDropdownItemType
type NormalizedDropdownItemType<ValueType = string> = 
      { label: string, value: ValueType, icons?: React.ComponentType<any>[] }`}
    </CodeBlock>
    <p>{`Siden `}<inlineCode parentName="p">{`NormalizedDropdownItemType`}</inlineCode>{` er en generisk type så kan `}<inlineCode parentName="p">{`value`}</inlineCode>{` være hvilken som helst type. TypeScript vil
da også gi deg riktig type ut i `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`. Den er satt til standard som `}<inlineCode parentName="p">{`string`}</inlineCode>{` da dette er vanligste praksis. `}</p>
    <h3 {...{
      "id": "liste-med-valgalternaltiver"
    }}>{`Liste med valgalternaltiver`}</h3>
    <p>{`Listen med valgalternaltiver sendes inn gjennom `}<inlineCode parentName="p">{`items`}</inlineCode>{`-prop-en. `}<inlineCode parentName="p">{`items`}</inlineCode>{` har typen `}<inlineCode parentName="p">{`PotentiallyAsyncDropdownItems`}</inlineCode>{`
som vil si den støtter tre typer input: liste med `}<inlineCode parentName="p">{`DropdownItemType`}</inlineCode>{`, synkrone funksjoner og asynkrone
funksjoner – de to siste kan du lese mer om under `}<a parentName="p" {...{
        "href": "#hente-valgalternaltiver-fra-nettverkskall"
      }}>{`Hente valgalternaltiver fra nettverkskall`}</a>{`.`}</p>
    <p><inlineCode parentName="p">{`DropdownItemType`}</inlineCode>{` er enten en `}<inlineCode parentName="p">{`string`}</inlineCode>{` eller et objekt med `}<inlineCode parentName="p">{`label`}</inlineCode>{`, `}<inlineCode parentName="p">{`value`}</inlineCode>{` og valgfritt `}<inlineCode parentName="p">{`icons`}</inlineCode>{` – se under.`}</p>
    <CodeBlock hideLineNumbers wrapLongLines={false} language="ts" mdxType="CodeBlock">
  {`// Definition of DropdownItemType
type DropdownItemType<ValueType = string> = 
      string |
      { label: string, value: ValueType, icons?: React.ComponentType<any>[] }`}
    </CodeBlock>
    <p>{`Disse kan også blandes slik at følgende også vil være et gyldig `}<inlineCode parentName="p">{`items`}</inlineCode>{`-input:`}</p>
    <CodeBlock hideLineNumbers wrapLongLines={false} language="ts" mdxType="CodeBlock">
  {`// Valid mixed type items input
const dropdownItems = [
  "choice1",
  { label: "Second choice", value: "choice2" },
  { lable: "Third choice", value: "choice3", icons: [FunnyIcon1, FunnyIcon2] }
]`}
    </CodeBlock>
    <h4 {...{
      "id": "egendefinert-filtreringsfunksjon"
    }}>{`Egendefinert filtreringsfunksjon`}</h4>
    <p>{`Som standard er søkefiltreringen bassert på en enkel regex som sjekker om input-et er en del av valgalternaltivet, ønsker du å endre
denne kan du skrive din egen filtreringsfunksjon til `}<inlineCode parentName="p">{`MultiSelect`}</inlineCode>{` gjennom prop-en `}<inlineCode parentName="p">{`itemFilter`}</inlineCode>{`. Dette kan være
nyttig hvis du f.eks ønsker å implementere `}<em parentName="p">{`fuzzy search`}</em>{` eller ignorere noen tegn fra input-et når du filtrerer.
`}<inlineCode parentName="p">{`itemFilter`}</inlineCode>{` tar inn en funksjon med `}<inlineCode parentName="p">{`item`}</inlineCode>{` (valgalternaltivet som skal sjekkes mot filtreringen) og `}<inlineCode parentName="p">{`inputValue`}</inlineCode>{`
(det bruker for øyeblikket har skrevet inn i inputfeltet) som input. Funksjonen skal returnere `}<inlineCode parentName="p">{`true`}</inlineCode>{` eller `}<inlineCode parentName="p">{`false`}</inlineCode>{`
avhengig av om gjeldende `}<inlineCode parentName="p">{`item`}</inlineCode>{` skal være med i listen over valg eller ikke.`}</p>
    <p>{`Se eksempel på dette under `}<a parentName="p" {...{
        "href": "#multiselect-med-egendefinert-filtreringsfunksjon"
      }}>{`MultiSelect med egendefingert filtreringsfunksjon-eksempelet`}</a>{`.`}</p>
    <h3 {...{
      "id": "hente-valgalternaltiver-fra-nettverkskall"
    }}>{`Hente valgalternaltiver fra nettverkskall`}</h3>
    <p>{`Hvis listen din med valg skal hentes fra et API eller lignende er det mulig å sende inn en memoisert funksjon til `}<inlineCode parentName="p">{`items`}</inlineCode>{`
i stedet for en liste med valg. Denne funksjonen kan enten være synkron eller asynkron og må enten returnere en
liste med `}<inlineCode parentName="p">{`DropdownItemType`}</inlineCode>{` direkte eller gjennom et `}<inlineCode parentName="p">{`Promise`}</inlineCode>{`. OBS: du må huske å `}<a parentName="p" {...{
        "href": "https://react.dev/reference/react/useCallback"
      }}>{`memoisere funksjonen`}</a>{` ved å bruke
`}<inlineCode parentName="p">{`React.useCallback`}</inlineCode>{`, ellers vil den kjøre funksjonen din oftere enn nødvendig!`}</p>
    <p><inlineCode parentName="p">{`items`}</inlineCode>{`-funksjonen kan også bruke `}<inlineCode parentName="p">{`inputValue`}</inlineCode>{`
-verdien som sendes inn å gjøre f.eks en query mot et API-et. I disse tilfellene kan prop-en `}<inlineCode parentName="p">{`debounceTimeout`}</inlineCode>{` være nyttig hvis du
ønsker å øke eller minke tiden det ventes før et nytt kall mot funksjonen skal kjøres etter brukeres slutter å skrive.`}</p>
    <p>{`For å avbryte utdaterte kall og hindre 'state update of unmounted component' kan du bruke `}<inlineCode parentName="p">{`abortControllerRef`}</inlineCode>{` som sendes inn som andre argument til funksjonen.
`}<inlineCode parentName="p">{`abortControllerRef.current.signal`}</inlineCode>{` inneholder et signal som sier fra når asynkrone kall bør avbrytes og er støttet ut av boksen i f.eks `}<inlineCode parentName="p">{`fetch`}</inlineCode>{` og `}<inlineCode parentName="p">{`axios`}</inlineCode>{`.
Les mer om AbortController `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/AbortController"
      }}>{`på Mozilla sine sider`}</a>{` og se eksempel på alt i bruk
under `}<a parentName="p" {...{
        "href": "#multiselect-med-valg-fra-nettverkskall"
      }}>{`MultiSelect med valg fra nettverkskall`}</a>{`.`}</p>
    <h3 {...{
      "id": "oppsummerings-chip-når-mange-elementer-er-valgt"
    }}>{`Oppsummerings-chip når mange elementer er valgt`}</h3>
    <p>{`For å unngå at skjemaelementet blir for stort vil chip-ene som viser hva som er valgt samles til én felles oppsummerings-chip når antallet
overstiger verdien satt i `}<inlineCode parentName="p">{`maxChips`}</inlineCode>{` – denne er som standard satt til `}<inlineCode parentName="p">{`10`}</inlineCode>{`. Se et eksempel på dette under `}<a parentName="p" {...{
        "href": "#endre-n%C3%A5r-oppsummerings-chip-vises"
      }}>{`Endre når oppsummerings-chip vises`}</a></p>
    <h2 {...{
      "id": "universell-utforming"
    }}>{`Universell utforming`}</h2>
    <p>{`Kommer info om dette snart`}</p>
    <h2 {...{
      "id": "retningslinjer"
    }}>{`Retningslinjer`}</h2>
    <p>{`Kommer snart`}</p>
    <h2 {...{
      "id": "eksempler"
    }}>{`Eksempler`}</h2>
    <h3 {...{
      "id": "standard-multiselect"
    }}>{`Standard MultiSelect`}</h3>
    <p>{`Her finner du en MultiSelect med standard-props.`}</p>
    <Playground __position={7} __code={'() => {\n  const [selected, setSelected] = React.useState([])\n  return (\n    <MultiSelect\n      label=\"Velg by\"\n      items={cities}\n      selectedItems={selected}\n      onChange={setSelected}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      MultiSelect,
      SmallExpandableAlertBox,
      CodeText,
      LinkText,
      ExpandableText,
      Playground,
      ImportStatement,
      CodeBlock,
      PageHeader,
      FILTERS,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      justifyContent: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        const [selected, setSelected] = React.useState([]);
        return <MultiSelect label="Velg by" items={cities} selectedItems={selected} onChange={setSelected} mdxType="MultiSelect" />;
      }}
    </Playground>
    <h3 {...{
      "id": "multiselect-med-egendefinert-filtreringsfunksjon"
    }}>{`MultiSelect med egendefinert filtreringsfunksjon`}</h3>
    <p>{`I dette eksempelet brukes en fuzzyMatch-implementasjon til å utføre en mer tilgivende
filtrering av de tilgjengelige valgene.`}</p>
    <Playground __position={8} __code={'() => {\n  const [selected, setSelected] = React.useState([])\n  return (\n    <MultiSelect\n      label=\"Fuzzy search filter\"\n      items={cities}\n      selectedItems={selected}\n      onChange={setSelected}\n      itemFilter={(item, inputValue) =>\n        fuzzyMatch(inputValue, item.label) > 0.5\n      }\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      MultiSelect,
      SmallExpandableAlertBox,
      CodeText,
      LinkText,
      ExpandableText,
      Playground,
      ImportStatement,
      CodeBlock,
      PageHeader,
      FILTERS,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      justifyContent: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        const [selected, setSelected] = React.useState([]);
        return <MultiSelect label="Fuzzy search filter" items={cities} selectedItems={selected} onChange={setSelected} itemFilter={(item, inputValue) => fuzzyMatch(inputValue, item.label) > 0.5} mdxType="MultiSelect" />;
      }}
    </Playground>
    <h3 {...{
      "id": "multiselect-med-valg-fra-nettverkskall"
    }}>{`MultiSelect med valg fra nettverkskall`}</h3>
    <p>{`Her har vi en eksempelfunksjon `}<inlineCode parentName="p">{`fetchItems`}</inlineCode>{` som bruker `}<inlineCode parentName="p">{`abortControllerRef`}</inlineCode>{`-argumentet og henter inn data fra et test-API.
Denne funksjonen settes så inn i `}<inlineCode parentName="p">{`items`}</inlineCode>{`-prop-en og vi får data fra det eksterne API-et listet opp når data-en er mottatt.`}</p>
    <Playground __position={9} __code={'() => {\n  // Husk å bruke useCallback for å unngå at funksjonen kjøres oftere enn nødvendig\n  const fetchItems = React.useCallback(async () => {\n    try {\n      const response = await fetch(\n        `https://dummyjson.com/products/categories`,\n      )\n      const data = await response.json()\n      if (data.message !== undefined) return [data.message]\n      return data.map(item => ({ label: item.name, value: item.slug }))\n    } catch (error) {\n      console.error(\'noe galt\')\n      return []\n    }\n  }, [])\n  const [selected, setSelected] = React.useState([])\n  return (\n    <MultiSelect\n      label=\"Extenal API list\"\n      items={fetchItems}\n      selectedItems={selected}\n      onChange={setSelected}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      MultiSelect,
      SmallExpandableAlertBox,
      CodeText,
      LinkText,
      ExpandableText,
      Playground,
      ImportStatement,
      CodeBlock,
      PageHeader,
      FILTERS,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      justifyContent: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        // Husk å bruke useCallback for å unngå at funksjonen kjøres oftere enn nødvendig
        const fetchItems = React.useCallback(async () => {
          try {
            const response = await fetch(`https://dummyjson.com/products/categories`);
            const data = await response.json();
            if (data.message !== undefined) return [data.message];
            return data.map(item => ({
              label: item.name,
              value: item.slug
            }));
          } catch (error) {
            console.error('noe galt');
            return [];
          }
        }, []);
        const [selected, setSelected] = React.useState([]);
        return <MultiSelect label="Extenal API list" items={fetchItems} selectedItems={selected} onChange={setSelected} mdxType="MultiSelect" />;
      }}
    </Playground>
    <h3 {...{
      "id": "multiselect-med-valg-fra-nettverkskall-bassert-på-tekstinput"
    }}>{`MultiSelect med valg fra nettverkskall bassert på tekstinput`}</h3>
    <p>{`Her har vi en eksempelfunksjon `}<inlineCode parentName="p">{`fetchItems`}</inlineCode>{` som henter inn data fra et test-API. `}<inlineCode parentName="p">{`fetchItems`}</inlineCode>{` tar inn
nåværende `}<inlineCode parentName="p">{`inputValue`}</inlineCode>{` og `}<inlineCode parentName="p">{`abortControllerRef`}</inlineCode>{` og bruker det til å gjøre en query mot API-et. Denne funksjonen
settes så inn i `}<inlineCode parentName="p">{`items`}</inlineCode>{`-prop-en og vi får data fra det eksterne API-et listet opp med query bassert på
det som skrives inn.`}</p>
    <Playground __position={10} __code={'() => {\n  // Husk å bruke useCallback for å unngå at funksjonen kjøres oftere enn nødvendig\n  const fetchItems = React.useCallback(\n    async (inputValue, abortControllerRef) => {\n      try {\n        const response = await fetch(\n          `https://dummyjson.com/products/search?q=${inputValue}&limit=15&select=title`,\n          // Bruk signalet fra abortControllerRef for å avbryte utdaterte kall\n          { signal: abortControllerRef.current.signal },\n        )\n        const data = await response.json()\n        if (data.message !== undefined) return [data.message]\n        const processedData = data.products.map(item => {\n          return { label: item.title, value: item.id }\n        })\n        return processedData\n      } catch (error) {\n        // AbortError må sendes videre til komponenten for å håndtere cleanup riktig\n        if (error && error.name === \'AbortError\') throw error\n        console.error(\'noe galt\')\n        return []\n      }\n    },\n    [],\n  )\n  const [selected, setSelected] = React.useState([])\n  return (\n    <MultiSelect\n      label=\"Extenal API list\"\n      items={fetchItems}\n      selectedItems={selected}\n      onChange={setSelected}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      MultiSelect,
      SmallExpandableAlertBox,
      CodeText,
      LinkText,
      ExpandableText,
      Playground,
      ImportStatement,
      CodeBlock,
      PageHeader,
      FILTERS,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      justifyContent: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        // Husk å bruke useCallback for å unngå at funksjonen kjøres oftere enn nødvendig
        const fetchItems = React.useCallback(async (inputValue, abortControllerRef) => {
          try {
            const response = await fetch(`https://dummyjson.com/products/search?q=${inputValue}&limit=15&select=title`, // Bruk signalet fra abortControllerRef for å avbryte utdaterte kall
            {
              signal: abortControllerRef.current.signal
            });
            const data = await response.json();
            if (data.message !== undefined) return [data.message];
            const processedData = data.products.map(item => {
              return {
                label: item.title,
                value: item.id
              };
            });
            return processedData;
          } catch (error) {
            // AbortError må sendes videre til komponenten for å håndtere cleanup riktig
            if (error && error.name === 'AbortError') throw error;
            console.error('noe galt');
            return [];
          }
        }, []);
        const [selected, setSelected] = React.useState([]);
        return <MultiSelect label="Extenal API list" items={fetchItems} selectedItems={selected} onChange={setSelected} mdxType="MultiSelect" />;
      }}
    </Playground>
    <h3 {...{
      "id": "slett-tekstinput-etter-valg"
    }}>{`Slett tekstinput etter valg`}</h3>
    <p>{`For å slette tekstinput-et etter at et element er valgt kan du burke prop-en ´clearInputOnSelect´.`}</p>
    <Playground __position={11} __code={'() => {\n  const [selected, setSelected] = React.useState([])\n  return (\n    <MultiSelect\n      label=\"Reset input on select\"\n      items={cities}\n      selectedItems={selected}\n      onChange={setSelected}\n      clearInputOnSelect\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      MultiSelect,
      SmallExpandableAlertBox,
      CodeText,
      LinkText,
      ExpandableText,
      Playground,
      ImportStatement,
      CodeBlock,
      PageHeader,
      FILTERS,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      justifyContent: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        const [selected, setSelected] = React.useState([]);
        return <MultiSelect label="Reset input on select" items={cities} selectedItems={selected} onChange={setSelected} clearInputOnSelect mdxType="MultiSelect" />;
      }}
    </Playground>
    <h3 {...{
      "id": "endre-når-oppsummerings-chip-vises"
    }}>{`Endre når oppsummerings-chip vises`}</h3>
    <p>{`Du kan endre hva som er maks antall valgte-elementer-chips som vises i inputfeltet med prop-en `}<inlineCode parentName="p">{`maxChips`}</inlineCode>{`.`}</p>
    <Playground __position={12} __code={'() => {\n  const [selected, setSelected] = React.useState([\n    { label: \'Oslo\', value: \'Oslo\' },\n    { label: \'Bergen\', value: \'Bergen\' },\n    { label: \'Kristiansand\', value: \'Kristiansand\' },\n    { label: \'Kristiansund\', value: \'Kristiansund\' },\n  ])\n  return (\n    <MultiSelect\n      label=\"Summery chip on 4 selected\"\n      items={cities}\n      selectedItems={selected}\n      onChange={setSelected}\n      maxChips={3}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      MultiSelect,
      SmallExpandableAlertBox,
      CodeText,
      LinkText,
      ExpandableText,
      Playground,
      ImportStatement,
      CodeBlock,
      PageHeader,
      FILTERS,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      justifyContent: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        const [selected, setSelected] = React.useState([{
          label: 'Oslo',
          value: 'Oslo'
        }, {
          label: 'Bergen',
          value: 'Bergen'
        }, {
          label: 'Kristiansand',
          value: 'Kristiansand'
        }, {
          label: 'Kristiansund',
          value: 'Kristiansund'
        }]);
        return <MultiSelect label="Summery chip on 4 selected" items={cities} selectedItems={selected} onChange={setSelected} maxChips={3} mdxType="MultiSelect" />;
      }}
    </Playground>
    <h2 {...{
      "id": "tilgjengelige-props"
    }}>{`Tilgjengelige props`}</h2>
    <h3 {...{
      "id": "multiselect"
    }}>{`MultiSelect`}</h3>
    <ImportStatement imports="MultiSelect" mdxType="ImportStatement" />
    <Props of={MultiSelect} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      